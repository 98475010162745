import { Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { persistor } from "store/configureStore";

export const Temporary404: React.FC = () => {
  useEffect(() => {
    // Clear all local storage to flush any unfinish application
    persistor.flush();
    persistor.purge();
  }, []);

  return (
    <Container component="main">
      <Typography variant="h3">
        Z power plans are not currently available. We are currently redesigning
        our Z power plans and we will be back later in the year.
      </Typography>
    </Container>
  );
};
